import React from "react";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const Container = styled.div``;

const Wrapper = styled.div`
  padding: 50px;
  display: flex;
`;
const InfoContainer = styled.div`
    flex: 1;
    padding 0px 50px;
`;

const ImgContainer = styled.div`
  flex: 1;
`;

const Image = styled.img`
  width: 80%;
  height: 90vh;
  object-fit: cover;
`;

const Title = styled.h1`
  font-wight: 200;
`;

const Desc = styled.p`
  margin: 20px 0px;
`;

const Price = styled.h1`
  font-wight: 400;
  font-size: 20px;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 30px 0px;
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
`;

const FilterTitle = styled.span`
  font-size: 15px;
  font-weight: 400;
`;

const FilterColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin: 0px 5px;
  cursor: pointer;
`;

const FilterSize = styled.select`
  margin-left: 10px;
  padding: 5px;
`;

const FilterSizeOption = styled.option``;

const AddContainer = styled.div`
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-between;
`;

const Quantity = styled.div`
    display: flex;
    align-items: center;
    font-weight: 700;
`


const Amount = styled.span`
    width: 30px;
    height: 30px;
    border: 1px solid pink;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
`;

const Button = styled.button`
    padding: 15px;
    border: 2px solid pink;
    background-color: white;
    cursor: pointer;
    font-weight: 700;
`;

const SizeGuide = styled.p`
    margin-bottom: 10px;
    cursor: pointer;
`

const Product = () => {
  return (
    <Container>
      <Wrapper>
        <ImgContainer>
          <Image src="https://img.ltwebstatic.com/images3_pi/2021/10/12/1634005516369f49cb614b2fec4c649b530c7763d6_thumbnail_600x.webp"></Image>
        </ImgContainer>
        <InfoContainer>
          <Title>Mock Neck Crop Top</Title>
          <Desc>
            he standard chunk of Lorem Ipsum used since the 1500s is reproduced
            below for those interested.
          </Desc>
          <Price>2500 LKR</Price>
          <FilterContainer>
            <Filter>
              <FilterTitle>Color</FilterTitle>
              <FilterColor color="black" />
              <FilterColor color="red" />
              <FilterColor color="blue" />
            </Filter>
            <Filter>
              <FilterTitle>Size</FilterTitle>
              <FilterSize>
                <FilterSizeOption>XXS</FilterSizeOption>
                <FilterSizeOption>XS</FilterSizeOption>
                <FilterSizeOption>S</FilterSizeOption>
                <FilterSizeOption>M</FilterSizeOption>
                <FilterSizeOption>L</FilterSizeOption>
                <FilterSizeOption>XL</FilterSizeOption>
                <FilterSizeOption>XXL</FilterSizeOption>
              </FilterSize>
            </Filter>
          </FilterContainer>
          <SizeGuide>Size Guide</SizeGuide>
          <AddContainer>
            <Quantity>
            <RemoveIcon />
            <Amount>1</Amount>
            <AddIcon />
 
            </Quantity>
           
          

            <Button>ADD TO CART</Button>
          </AddContainer>
        </InfoContainer>
      </Wrapper>
    </Container>
  );
};

export default Product;
