import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import { Menu, Home } from '@mui/icons-material';

const NavbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background-color: #2d3748;
`;

const Title = styled.p`
  text-transform: uppercase;
  font-size: 2rem; 
  color: #fc8181;
`;

const IconStyled = styled(IconButton)`
  color: white;
`;

const Navbar = () => {
  return (
    <NavbarContainer>
      <Title>lawproducts</Title>
    </NavbarContainer>
  );
}

export default Navbar;
