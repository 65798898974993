import download from './assert/download.jpeg';
import download1 from './assert/download1.jpg';
import download2 from './assert/download2.jpg';

export const sliderItems = [
    {
        id: 1,
        img: download,
        title: 'New Arrival',
        desc: 'Get Flat 20% Off For Eliana Store',
        
    },

    {
        id: 2,
        img: download1,
        title: 'Popular Items',
        desc: 'Get Flat 20% Off For Eliana Store'
    },

    {
        id: 1,
        img: download2,
        title: 'Top Rated Items',
        desc: 'Get Flat 20% Off For Eliana Store'
    },


];

export const categories = [
    {
        id: 1,
        img: "https://images.pexels.com/photos/5886041/pexels-photo-5886041.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        title: "SHIRT STYLE!",
      },
      {
        id: 2,
        img: "https://images.pexels.com/photos/2983464/pexels-photo-2983464.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        title: "LOUNGEWEAR LOVE",
      },
      {
        id: 3,
        img: "https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        title: "LIGHT JACKETS",
      },
      
];

export const popularProducts = [
    {
      id:1,
      img:"https://d3o2e4jr3mxnm3.cloudfront.net/Mens-Jake-Guitar-Vintage-Crusher-Tee_68382_1_lg.png",
      name: 'T-Shirt',
      price: '2,500'
    },
    {
      id:2,
      img:"https://hameedia.com/wp-content/uploads/2024/02/Hameedia-web_0005_DSC02327-1.jpg",
      name: 'Shirt'
    },
    {
      id:3,
      img:"https://finebrandz.lk/cdn/shop/files/8905425748372-01_700x.jpg?v=1688983652",
      name: 'Trousers'
    },
    {
      id:4,
      img:"https://contents.mediadecathlon.com/p2197356/20ec45d54c1ff25c1d2a554ad65cd203/p2197356.jpg",
      name: 'Shorts'
    },
    {
      id:5,
      img:"https://images.ctfassets.net/5gvckmvm9289/3BlDoZxSSjqAvv1jBJP7TH/65f9a95484117730ace42abf64e89572/Noissue-x-Creatsy-Tote-Bag-Mockup-Bundle-_4_-2.png",
      name: 'Bags'
    },
    {
      id:6,
      img:"https://d3o2e4jr3mxnm3.cloudfront.net/Rocket-Vintage-Chill-Cap_66374_1_lg.png",
      name: 'Caps'
    },
    {
      id:8,
      img:"https://www.pngarts.com/files/3/Women-Jacket-PNG-High-Quality-Image.png",
      name: 'Jackets'
    },
    
  ]

