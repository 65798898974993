import './index.css';
import Home from './pages/Home';
import ProductList from './pages/ProductList';
import React from 'react';
import Product from './pages/Product';
import Register from './pages/Register';
// import CustomScript from '../CustomScript';

function App() {
  return (
    <div>
      {/* <CustomScript /> */}
      <Home className="" />
    </div>
  );
}

export default App;
