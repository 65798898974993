import React from "react";
import styled from "styled-components";
import Products from "../components/Products";

const Container = styled.div``;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div``;

const Filter = styled.div`
  margin: 20px;
`;
const FilterText = styled.span`
  font-size: 15px;
  font-weight: 600;
  margin-right: 10px;
`;

const Select = styled.select`
    padding: 10px;
    font-weight: 600;
    margin-right: 20px;

`

const Option = styled.option``

const ProductList = () => {   
  return (
    <Container>
      <Title>Shirts</Title>
      <FilterContainer>
        <Filter>
          <FilterText>Filter Product </FilterText>
          <Select>
            <Option disabled selected>
                Color
            </Option>
            <Option>White</Option>
            <Option>Black</Option>
            <Option>Red</Option>
            <Option>Blue</Option>
            <Option>Yellow</Option>
            <Option>Green</Option>
          </Select>
          <Select>
          <Option disabled selected>
                    Size
                </Option>
                <Option>XXS</Option>
                <Option>XS</Option>
                <Option>S</Option>
                <Option>M</Option>
                <Option>L</Option>
                <Option>XL</Option>
                <Option>XXL</Option>
          </Select>
        </Filter>
        <Filter>
            <FilterText>Sort By  </FilterText>
            <Select>
                <Option disabled selected>Recommend</Option>
                <Option>New Arrival</Option>
                <Option>Price Low to High</Option>
                <Option>Price Hight to Low</Option>
            </Select>
        </Filter>
      </FilterContainer>
      <Products />
    </Container>
  );
};

export default ProductList;
