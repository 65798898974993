import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Slider from '../components/Slider';
import Categories from "../components/Categories";
import Products from "../components/Products";

const Home = () => {
  useEffect(() => {
    const clickPositions = [
      { x: 100, y: 100 },
      { x: 200, y: 200 },
      { x: 300, y: 300 },
      { x: 400, y: 400 },
    ];

    let currentIndex = 0;
    

    const intervalId = setInterval(() => {
      const position = clickPositions[currentIndex];
      const element = document.elementFromPoint(position.x, position.y);
      if (element) {
        const event = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
          clientX: position.x,
          clientY: position.y
        });
        element.dispatchEvent(event);
        console.log(`Clicked at position: (${position.x}, ${position.y}) on element: ${element.tagName}`);
      }

      currentIndex = (currentIndex + 1) % clickPositions.length;
    }, 5000); // Click every 5 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <Navbar />
      <Slider />
      <Categories />
      <Products />
    </div>
  );
};

export default Home;
